import React from "react";
import {
  FaDiscord,
  FaTelegramPlane,
  FaTwitter,
  FaMediumM,
} from "react-icons/fa";
import { IconContext } from "react-icons";

export default function navbarItems() {
  return (
    <div>
      <div className="flex flex-col">
        <a
          href="https://app.senseifi.io"
          target="_blank"
          rel="noreferrer"
          className="border-b border-white  py-5 text-lg"
        >
          Sei Dapp
        </a>
        <a
          href="https://vara.senseifi.io"
          target="_blank"
          rel="noreferrer"
          className="border-b border-white  py-5 text-lg"
        >
          Vara Dapp
        </a>
      </div>
      <div className="my-20 flex gap-10">
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://medium.com/@savvio_fi">
            <FaMediumM />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://t.me/Savvio_DeFi">
            <FaTelegramPlane />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://twitter.com/Savvio_DeFi">
            <FaTwitter />
          </a>
        </IconContext.Provider>
        <IconContext.Provider value={{ className: "text-2xl" }}>
          <a href="https://discord.gg/xJUE8Bxn">
            <FaDiscord />
          </a>
        </IconContext.Provider>
      </div>
    </div>
  );
}
