import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Timeline.css";

const VTEProps = {
  className: "vertical-timeline-element--work",
  contentStyle: {
    color: "#071428",
    boxShadow: "none",
    textAlign: "left",
    padding: "1rem",
  },
  contentArrowStyle: {
    display: "none",
  },

  dateClassName: "milestone",
  iconStyle: {
    background: "#fff",
    color: "#071428",
    fontSize: "1.5rem",
    fontWeight: 400,
    border: "1px solid #071428",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0 0 20px #00000050, inset 0 0 5px #00000050",
  },
  position: "right",
};

export default function Timeline() {
  return (
    <VerticalTimeline layout="2-columns">
      {/* <VerticalTimelineElement {...VTEProps} date="Q3 2023" icon={<p>1.0</p>}>
        <h3 className="vertical-timeline-element-title">Creative Director</h3>
        <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4>
        <p>
          • Beta APTOS Savvio Save Game
          <br />
          <br />
          • Beta SUI Savvio Save Game
          <br />
          <br />
          • Beta SKALE Savvio Save Game
          <br />
          <br />• Beta SEI Savvio Save Game
        </p>
      </VerticalTimelineElement> */}
      <VerticalTimelineElement {...VTEProps} date="Q4 2023" icon={<p>2.0</p>}>
        <p>
          • VARA MVP Security Audits
          <br />
          <br />
          • VARA MVP Live - Savvio Save Game
          <br />
          <br />
          • Savvio and VARA Co-Marketing
          <br />
          <br />
          • Go to market activation
          <br />
          <br />• SenSei Fi acquisition
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement {...VTEProps} date="Q1 2024" icon={<p>3.0</p>}>
        <p>
          • Bridge Integration
          <br />
          <br />• Liquidity pools
          <br />
          <br />• SAVV Token IDO
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement {...VTEProps} date="Q2 2024" icon={<p>4.0</p>}>
        <p>
          • DEX Router and Aggregator integration
          <br />
          <br />
          • Yield aggregator
          <br />
          <br />
          • MVP&apos;s on Sui and Arbitrium
          <br />
          <br />• BTCb DeFi products go live
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
}
