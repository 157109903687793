import "./App.css";
import "./input.css";
import React, { useState, useEffect } from "react";
import WhitelistForm from "./components/WhitelistForm";
import mockup from "./assets/mockup.png";
import { FaDiscord, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import token from "./assets/sensei-coin.png";
// partners
// import braindex from "./assets/partners/Briandex Logo.png";
// import falconx from "./assets/partners/FalconX Logo.png";
// import mexc from "./assets/partners/MEXC Logo.png";
// import sparrowswap from "./assets/partners/Sparrowswap.png";
// import wormhole from "./assets/partners/Wormhole.png";
// import yieldly from "./assets/partners/Yieldly logo.png";

import beanie from "./assets/team/beanie.jpg";
import seijas from "./assets/team/seijas.jpg";
import porky from "./assets/team/porky.jpg";
import jelly from "./assets/team/jelly.jpg";

import { IconContext } from "react-icons";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import Timeline from "./components/Timeline";

const TARGET_TIME = "2023-06-06T14:00:00Z";

const team = [
  {
    name: "Seijas san",
    image: seijas,
    info: "Data-driven technologist with over 5 years blockchain and start up experience. Passionate about innovation.",
  },
  {
    name: "Beanie san",
    image: beanie,
    info: "Entrepreneur with a strong history of building & supporting the growth of some of the largest distributed ledger & DeFi companies.",
  },
  {
    name: "Porky san",
    image: porky,
    info: "Blockchain developer with over 10 years design experience. Focussed on delivering cross-chain websites and webapps.",
  },
];

export default function Home() {
  const [showWhitelist, setShowWhitelist] = useState(true);
  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000); // Get current unix time
    const targetTime = Math.floor(new Date(TARGET_TIME).getTime() / 1000); // Set target unix time
    // console.log(currentTime + ";" + targetTime);
    if (currentTime >= targetTime) {
      setShowWhitelist(false);
    }
  }, []);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <section
        className="App relative h-auto w-full overflow-hidden 
  bg-blue-dark bg-gradient-to-t from-white via-blue-600 to-blue-dark text-center"
      >
        <div
          className="absolute -right-[50%] bottom-0 h-2/3 w-2/3 bg-yellow bg-opacity-70 blur-[10rem]"
          style={{
            filter: "blur(100px)",
            borderRadius: "50%",
            boxShadow: "0 0 100px #FFDB2C",
            //transform: `translate(${position.x * 0.1}px, ${position.y * 0.1}px)`,
          }}
        />
        <div
          className="absolute -left-[50%] top-0 h-2/3 w-2/3 bg-yellow bg-opacity-70 blur-[10rem]"
          style={{
            filter: "blur(100px)",
            borderRadius: "50%",
            boxShadow: "0 0 100px #FFDB2C",
            // transform: `translate(${position.x * -0.1}px, ${
            //   position.y * -0.1
            // }px)`,
          }}
        />
        <div className="translate-y-1/4">
          <div className="p-5 md:mt-20">
            <h1 className="text-5xl  md:text-[4rem] ">
              The Ultimate Destination for
              <br />
              <span className="font-bold">
                Gamified&nbsp;DeFi and Cross&nbsp;Chain&nbsp;Yield
              </span>
            </h1>
            {/* <h2 className="mt-5 text-4xl font-bold md:text-[4rem]">
              with the spiciest&nbsp;rewards.
            </h2> */}
            <h3 className="mx-auto mt-10 mb-5 max-w-5xl text-xl font-light md:w-4/5 md:text-3xl">
              Savvio combines a no loss prize game, bonus rewards, and DeFi
              tools for maximum yield across Vara, Sei and more.
            </h3>
          </div>

          <div className="mx-7 mb-28 flex justify-center">
            <div className="relative mx-2 my-2 max-w-sm items-center rounded border-2 border-transparent bg-yellow px-5 py-2 text-2xl text-blue-dark shadow-yellow transition-all hover:cursor-pointer hover:border-white hover:bg-yellow/50  hover:text-blue-dark md:mx-2 md:w-80">
              <a
                href="https://app.savvio.io"
                className=" flex items-center justify-around hover:text-white"
                target="_blank"
              >
                Sei DApp
              </a>
            </div>
            <div className="relative mx-2 my-2 max-w-sm items-center rounded border-2 border-transparent bg-green-400 px-5 py-2 text-2xl text-blue-dark shadow-yellow transition-all hover:cursor-pointer hover:border-white hover:bg-yellow/50  hover:text-blue-dark md:mx-2 md:w-80">
              <a
                href="https://vara.savvio.io"
                className=" flex items-center justify-around hover:text-white"
                target="_blank"
              >
                Vara DApp
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
      <div className="p-5 mt-12">
        <h2 className="text-4xl md:text-[4rem]">Our partners</h2>
        <p className="my-7 md:mx-32 text-xl md:text-3xl ">
          We're working with the great minds at the projects below to deliver
          you the greatest experience!
        </p>

        <div
          class="grid md:grid-cols-2 lg:grid-cols-6 
         p-6 mb-10  rounded-md items-center content-stretch backdrop-blur-xl bg-white/30 "
        >
          <div class="relative mb-12 lg:mb-0 mx-auto h-full flex items-center sm:max-w-[400px] grayscale">
            <img alt="Braindex" src={braindex} className="max-h-24 px-4" />
            <div class="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-800 to-transparent opacity-25 dark:opacity-100 lg:block"></div>
          </div>

          <div class="relative mb-12 lg:mb-0 mx-auto h-full flex items-center sm:max-w-[400px] grayscale">
            <img alt="FlaconX" src={falconx} className="max-h-24 px-2" />
            <div class="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-800 to-transparent opacity-25 dark:opacity-100 lg:block"></div>
          </div>

          <div class="relative flex w-full justify-center mb-12 lg:mb-0 mx-auto h-full flex items-center sm:max-w-[400px] grayscale">
            <img alt="Mexc Global" src={mexc} className="max-h-24 px-2 " />
            <div class="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-800 to-transparent opacity-25 dark:opacity-100 lg:block"></div>
          </div>

          <div class="relative flex w-full justify-center mb-12 lg:mb-0 mx-auto h-full flex items-center sm:max-w-[400px] grayscale">
            <div>
              <img
                alt="Sparrowswap"
                src={sparrowswap}
                className="max-h-24 m-auto contrast-200 px-2"
              />
              <p className="text-black font-bold text-lg">Sparrowswap</p>
            </div>
            <div class="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-800 to-transparent opacity-25 dark:opacity-100 lg:block"></div>
          </div>

          <div class="relative flex w-full justify-center mb-12 lg:mb-0 mx-auto h-full flex items-center sm:max-w-[400px] grayscale">
            <img alt="Wormhole" src={wormhole} className="max-h-24 px-4" />
            <div class="absolute right-0 top-0 hidden h-full min-h-[1em] w-px self-stretch border-t-0 bg-gradient-to-tr from-transparent via-neutral-800 to-transparent opacity-25 dark:opacity-100 lg:block"></div>
          </div>

          <div class="relative flex w-full justify-center mb-12 lg:mb-0 mx-auto h-full flex items-center sm:max-w-[400px] grayscale">
            <img alt="Yieldly" src={yieldly} className="max-h-24 px-4" />
          </div>
        </div>
      </div>
    </section> */}
      {/* <section>
      <div className="mx-5 mt-10 flex flex-col items-center justify-center text-center md:mx-auto md:flex-row">
        <h2 className="  flex-wrap items-center text-5xl font-thin">
          Get&nbsp;whitelisted for
        </h2>
        <div className="flex items-center">
          <span className=" text-5xl font-medium text-yellow">
            &nbsp;SEN&nbsp;
          </span>
          <img src={token} className="inline-block w-16" />
        </div>
      </div>
      {showWhitelist ? (
        <>
          {
            <div className="flex flex-col items-center">
              <p className="my-2 text-lg ">Round 2: Ends in</p>
              <FlipClockCountdown
                to={TARGET_TIME}
                labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                labelStyle={{
                  fontSize: 10,
                  fontWeight: 600,
                  textTransform: "uppercase",
                  color: "#FFF",
                }}
                digitBlockStyle={{
                  width: 30,
                  height: 40,
                  fontSize: 24,
                  color: "#071428",
                  background: "#FFF",
                }}
                dividerStyle={{ color: "#07142895", height: 1 }}
                separatorStyle={{
                  color: "#071428",
                  size: "4px",
                }}
                duration={0.5}
              />
            </div>
          }
          <WhitelistForm />
        </>
      ) : (
        <>
          <div className="mx-auto mt-10 mb-32 max-w-xl rounded-xl border-2 border-yellow bg-blue-dark p-5 text-white md:w-max ">
            <p className="flex items-center text-lg">
              Whitelisting has ended for this round.
              <br />
              <br />
              Keep a close eye on our socials for more updates!
            </p>
          </div>
        </>
      )}
    </section> */}

      <section
        className=" 
  mx-auto flex w-full max-w-6xl flex-col text-left md:flex-row md:text-center"
      >
        <div className="m-10 max-w-md md:my-16 md:mx-10">
          <h2 className="text-left text-6xl font-medium text-blue-dark">
            The <span className="text-yellow">Road</span> Ahead
          </h2>
        </div>
        <div className="m-10 md:w-full">
          <Timeline />
        </div>
      </section>
      <div className="relative">
        <div
          className="from-40% absolute bottom-0 z-10 h-32 w-full
       bg-gradient-to-t from-[#ffffff] md:h-48"
        />
      </div>
      <section
        className=" 
  mx-auto my-20 flex w-full max-w-6xl flex-col text-left md:flex-row md:text-center"
      >
        <div className="m-10 max-w-md md:my-16 md:mx-10">
          <h2 className="text-left text-6xl font-medium text-blue-dark">
            Meet the <span className="text-yellow">Team</span>
          </h2>
          <p className="my-5 text-left text-blue-dark">
            Savvio is built and backed by industry experts with multiple years
            of collective Web3 experience.
          </p>
        </div>
        <div className="m-0 grid gap-10 sm:m-10 sm:grid-cols-2 md:w-full">
          {team.map((human, i) => (
            <div
              key={i}
              className="relative mx-auto h-80 w-64 rounded-lg border border-blue-dark bg-white drop-shadow-2xl hover:drop-shadow"
            >
              <div className="absolute">
                <img
                  className=" inset-0 z-0 rounded-lg bg-contain bg-center"
                  src={human.image}
                />
                <h4 className="z-10 my-2 w-full text-center text-2xl font-bold text-blue-dark drop-shadow-md">
                  {human.name}
                </h4>
              </div>
              <div className="absolute inset-0 z-10 flex flex-col items-center justify-center rounded-lg bg-white/70 p-4 text-blue-dark opacity-0 backdrop-blur-lg duration-300 hover:opacity-100">
                <h4 className="z-10 my-2 w-full text-center text-2xl font-bold text-blue-dark drop-shadow-md">
                  {human.name}
                </h4>
                {human.info}
              </div>
            </div>
          ))}
        </div>
      </section>
      <footer className="w-full ">
        {/* <img src={mockup} className="h-auto w-full" /> */}
        <div className="bg-blue-dark py-5 text-center font-light">
          <div className="mx-5 flex flex-col justify-between md:flex-row">
            <p>Copyright &copy; Savvio, 2023.</p>
            <div className="mt-2 flex flex-col md:mt-0 md:flex-row  md:gap-5">
              <a
                href="https://branding.savvio.io/savvio%20logos.zip"
                style={{ textDecoration: "underline" }}
              >
                Download Branding
              </a>
              <a target="_blank" href="https://app.savvio.io/tnc">
                Terms & Condition
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
